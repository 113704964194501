var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showChat
        ? _c("chat-container", {
            attrs: {
              currentUserId: _vm.currentUserId,
              theme: _vm.theme,
              isDevice: _vm.isDevice,
              firebaseChatService: _vm.firebaseChatService,
            },
            on: {
              "show-demo-options": function ($event) {
                _vm.showDemoOptions = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }