var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "window-container",
      class: { "window-mobile": _vm.isDevice },
    },
    [
      _c("chat-window", {
        attrs: {
          height: _vm.screenHeight,
          theme: _vm.theme,
          styles: _vm.styles,
          "current-user-id": _vm.currentUserId,
          "room-id": _vm.roomId,
          rooms: _vm.loadedRooms,
          "loading-rooms": _vm.loadingRooms,
          messages: _vm.messages,
          "messages-loaded": _vm.messagesLoaded,
          "message-actions": _vm.messageActions,
          "rooms-loaded": _vm.roomsLoaded,
          "room-actions": _vm.roomActions,
          "menu-actions": _vm.menuActions,
          "room-message": _vm.roomMessage,
          "show-add-room": _vm.showAddRoom,
          "text-messages": {
            ROOMS_EMPTY:
              "Nenhuma mensagem ou grupo encontrado.\n\nSe increva ou seja convidado para uma turma para ter acesso.",
            ROOM_EMPTY: "Nenhuma conversa selecionada ",
            NEW_MESSAGES: "Novas mensagens!",
            MESSAGE_DELETED: "Mensagem deletada ",
            MESSAGES_EMPTY: "Nenhuma mensagem ",
            CONVERSATION_STARTED: "A conversa começou em: ",
            TYPE_MESSAGE: "Digite sua mensagem ",
            SEARCH: "Procurar ",
            IS_ONLINE: "está online ",
            LAST_SEEN: "última conexão ",
            IS_TYPING: "está digitando ... ",
          },
        },
        on: {
          "fetch-more-rooms": _vm.fetchMoreRooms,
          "fetch-messages": _vm.fetchMessages,
          "send-message": _vm.sendMessage,
          "edit-message": _vm.editMessage,
          "delete-message": _vm.deleteMessage,
          "open-file": _vm.openFile,
          "open-user-tag": _vm.openUserTag,
          "add-room": function ($event) {
            return _vm.showRoomForm()
          },
          "room-action-handler": _vm.menuActionHandler,
          "menu-action-handler": _vm.menuActionHandler,
          "send-message-reaction": _vm.sendMessageReaction,
          "typing-message": _vm.typingMessage,
          "toggle-rooms-list": function ($event) {
            return _vm.$emit("show-demo-options", $event.opened)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "room-list-options",
            fn: function (ref) {
              var room = ref.room
              return [
                _vm.roomActions.length
                  ? _c(
                      "div",
                      {
                        staticClass: "vac-svg-button vac-list-room-options",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.roomMenuOpened = room && room.roomId
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { name: "dropdown", param: "room" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.roomActions.length
                  ? _c("transition", { attrs: { name: "vac-slide-left" } }, [
                      _vm.roomMenuOpened === (room && room.roomId)
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "click-outside",
                                  rawName: "v-click-outside",
                                  value: _vm.closeRoomMenu,
                                  expression: "closeRoomMenu",
                                },
                              ],
                              staticClass: "vac-menu-options",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "vac-menu-list" },
                                _vm._l(_vm.roomActions, function (action) {
                                  return _c("div", { key: action.name }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vac-menu-item",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.roomActionHandler(action)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(action.title) + " ")]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "room-header-info",
            fn: function (ref) {
              var room = ref.room
              var typingUsers = ref.typingUsers
              var userStatus = ref.userStatus
              return [
                _c("div", { staticClass: "vac-text-ellipsis" }, [
                  _c(
                    "div",
                    { staticClass: "vac-room-name vac-text-ellipsis" },
                    [_vm._v(" " + _vm._s(room && room.roomName) + " ")]
                  ),
                  typingUsers
                    ? _c(
                        "div",
                        { staticClass: "vac-room-info vac-text-ellipsis" },
                        [_vm._v(" " + _vm._s(typingUsers) + " ")]
                      )
                    : _c(
                        "div",
                        { staticClass: "vac-room-info vac-text-ellipsis" },
                        [_vm._v(" " + _vm._s(userStatus) + " ")]
                      ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            title: (this.formRoomId ? "Editar" : "Criar") + " Grupo",
            active: _vm.viewGroupFormModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.viewGroupFormModal = $event
            },
          },
        },
        [
          _c("ChatRoomForm", {
            ref: "chatroomform",
            attrs: { firebaseChatService: _vm.firebaseChatService },
            on: {
              "confirm-alteration": _vm.roomAltered,
              "cancel-alteration": function ($event) {
                _vm.viewGroupFormModal = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }