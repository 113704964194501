<template>
  <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <chat-window
      :height="screenHeight"
      :theme="theme"
      :styles="styles"
      :current-user-id="currentUserId"
      :room-id="roomId"
      :rooms="loadedRooms"
      :loading-rooms="loadingRooms"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      :message-actions="messageActions"
      :rooms-loaded="roomsLoaded"
      :room-actions="roomActions"
      :menu-actions="menuActions"
      :room-message="roomMessage"
      :show-add-room="showAddRoom"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
      @edit-message="editMessage"
      @delete-message="deleteMessage"
      @open-file="openFile"
      @open-user-tag="openUserTag"
      @add-room="showRoomForm()"
      @room-action-handler="menuActionHandler"
      @menu-action-handler="menuActionHandler"
      @send-message-reaction="sendMessageReaction"
      @typing-message="typingMessage"
      @toggle-rooms-list="$emit('show-demo-options', $event.opened)"
      :text-messages="{
        ROOMS_EMPTY: 'Nenhuma mensagem ou grupo encontrado.\n\nSe increva ou seja convidado para uma turma para ter acesso.',
        ROOM_EMPTY: 'Nenhuma conversa selecionada ',
        NEW_MESSAGES: 'Novas mensagens!',
        MESSAGE_DELETED: 'Mensagem deletada ',
        MESSAGES_EMPTY: 'Nenhuma mensagem ',
        CONVERSATION_STARTED: 'A conversa começou em: ',
        TYPE_MESSAGE: 'Digite sua mensagem ',
        SEARCH: 'Procurar ',
        IS_ONLINE: 'está online ',
        LAST_SEEN: 'última conexão ',
        IS_TYPING: 'está digitando ... '
      }"
    >
    
    <template v-slot:room-list-options="{ room }">
        <div
          v-if="roomActions.length"
          class="vac-svg-button vac-list-room-options"
          @click.stop="roomMenuOpened = (room && room.roomId)"
        >
          <svg-icon name="dropdown" param="room" />
        </div>
        <transition v-if="roomActions.length" name="vac-slide-left">
          <div
            v-if="roomMenuOpened === (room && room.roomId)"
            v-click-outside="closeRoomMenu"
            class="vac-menu-options"
          >
            <div class="vac-menu-list">
              <div v-for="action in roomActions" :key="action.name">
                <div
                  class="vac-menu-item"
                  @click.stop="roomActionHandler(action)"
                >
                  {{ action.title }}
                </div>
              </div>
            </div>
          </div>
        </transition>
    </template>

    <template v-slot:room-header-info="{ room, typingUsers, userStatus}">
      <div class="vac-text-ellipsis">
        <div class="vac-room-name vac-text-ellipsis">
          {{ room && room.roomName }}
        </div>
        <div v-if="typingUsers" class="vac-room-info vac-text-ellipsis">
          {{ typingUsers }}
        </div>
        <div v-else class="vac-room-info vac-text-ellipsis">
          {{ userStatus }}
        </div>
      </div>
    </template>

    </chat-window>
    <vs-popup
    :title="`${this.formRoomId ? 'Editar' : 'Criar'} Grupo`"
    :active.sync="viewGroupFormModal"
    >
      <ChatRoomForm 
       @confirm-alteration="roomAltered"
       @cancel-alteration="viewGroupFormModal = false"
       ref="chatroomform"
       :firebaseChatService="firebaseChatService"
      >
      </ChatRoomForm>
    </vs-popup>
  </div>
</template>

<script>
import FirebaseChatService from '../services/firebase/FirebaseChatService'
import { parseTimestamp, isSameDay } from './utils/dates'
import ChatWindow from 'educatena-chat'
import 'educatena-chat/dist/vue-advanced-chat.css'
import SvgIcon from './SvgIcon'
import ChatRoomForm from './ChatRoomForm'

export default {
  components: {
    ChatWindow,
    SvgIcon,
    ChatRoomForm
  },

  props: ['currentUserId', 'theme', 'isDevice', 'firebaseChatService'],

  data() {
    return {
      roomsPerPage: 15,
      rooms: [],
      roomMenuOpened: null,
      roomId: '',
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      messageActions: [],
      roomMessage: '',
      startMessages: null,
      endMessages: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      roomActions: [],
      menuActions: [],
      styles: { container: { borderRadius: '4px' } },
      viewGroupFormModal: false,
      formRoomId: null
      // ,dbRequestCount: 0
    }
  },

  mounted() {
    this.fetchRooms()
    this.updateUserOnlineStatus()

    const actions = [
      { name: 'updateRoom', title: 'Editar Grupo', rule: 'chat.update_room' },
      { name: 'deleteRoom', title: 'Excluir Grupo', rule: 'chat.delete_rooms' }
    ]

    this.roomActions = actions.filter(action => {
      return this.$acl.hasPermission(action.rule)
    })

    this.menuActions = this.roomActions

    this.messageActions = [
      { name: 'replyMessage', title: 'Responder' },
      { name: 'editMessage', title: 'Editar Mensagem', onlyMe: true },
      { name: 'deleteMessage', title: 'Excluir Mensagem', onlyMe: true }
    ]
  },

  destroyed() {
    this.resetRooms()
  },

  computed: {
    loadedRooms() {
      return this.rooms.slice(0, this.roomsLoadedCount)
    },
    screenHeight() {
      return this.isDevice ? `${window.innerHeight  }px` : 'calc(100vh - 140px)'
    },
    showAddRoom() {
      return this.$acl.hasPermission('chat.create_new_rooms')
    }
  },

  watch: {
    viewGroupFormModal(show) {
      if (!show) {
        this.formRoomId = null
        this.$refs.chatroomform.resetForm()
      }
    }
  },

  methods: {
    dlog(...things) {
      this.devLog('chat-container', ...things)
    },

    roomActionHandler(action) {
      this.roomId = this.roomMenuOpened
      this.closeRoomMenu()
      this.menuActionHandler({ action, roomId: this.roomId })
    },

    closeRoomMenu() {
      this.roomMenuOpened = null
    },

    resetRooms() {
      this.loadingRooms = true
      this.loadingLastMessageByRoom = 0
      this.roomsLoadedCount = 0
      this.rooms = []
      this.roomsLoaded = true
      this.startRooms = null
      this.endRooms = null
      this.roomsListeners.forEach(listener => listener())
      this.roomsListeners = []
      this.resetMessages()
    },

    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.startMessages = null
      this.endMessages = null
      this.listeners.forEach(listener => listener())
      this.listeners = []
    },

    fetchRooms() {
      this.resetRooms()
      this.fetchMoreRooms()
    },

    async fetchMoreRooms() {
      // If in a previous fetchMoreRooms call, the pagination fetched the
      // last rooms and there isnt more rooms to fetch, then do not execute fetchMoreRooms.
      if (this.endRooms && !this.startRooms) return (this.roomsLoaded = true)

      // Query: Get rooms in which the current user is a part of, ordered by the 
      // last updated one, limiting the amount of rooms returned by pagination.
      let query = this.firebaseChatService.roomsRef
        .where('users', 'array-contains', this.currentUserId.toString())
        .orderBy('lastUpdated', 'desc')
        .limit(this.roomsPerPage)

      // Pagination: If a starting room has been defined, make the query start after it,
      // ignoring previous rooms.
      if (this.startRooms) query = query.startAfter(this.startRooms)

      // Fetch rooms
      const rooms = await query.get()
      // this.incrementDbCounter('Fetch Rooms', rooms.size)

      // Pagination: If there is no more rooms, or the amount of rooms returned
      // is smaller than rooms per page, then there's nothing more to get.
      this.roomsLoaded = rooms.empty || rooms.size < this.roomsPerPage

      // Pagination: reset start room to the last room currently found.
      if (this.startRooms) this.endRooms = this.startRooms
      this.startRooms = rooms.docs[rooms.docs.length - 1]

      // Get the id of all users in the found rooms that arent loaded in AllUsers yet.
      const roomUserIds = []
      rooms.forEach(room => {
        room.data().users.forEach(userId => {
          const foundUser = this.allUsers.find(user => toString(user._id) === toString(userId))
          if (!foundUser && roomUserIds.indexOf(userId) === -1) {
            roomUserIds.push(userId)
          }
        })
      })

      // this.incrementDbCounter('Fetch Room Users', roomUserIds.length)

      // Finds all users present in the rooms' users fields.
      const rawUsers = []
      roomUserIds.forEach(userId => {
        const promise = this.firebaseChatService.usersRef
          .doc(userId)
          .get()
          .then(user => user.data())

        rawUsers.push(promise)
      })

      // Loads newly found users to data allUsers
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))]

      // Populate a roomList object, getting its users (based on id) from
      // data AllUsers, previously concatenated with found users of found rooms.
      const roomList = {}
      rooms.forEach(room => {
        roomList[room.id] = { ...room.data(), users: [] }

        room.data().users.forEach(userId => {
          const foundUser = this.allUsers.find(user => user._id === userId)
          if (foundUser) roomList[room.id].users.push(foundUser)
        })
      })

      const formattedRooms = []
      // Format rooms from roomsList, setting the roomAvatar to the first
      // user's avatar that isnt the current user.
      Object.keys(roomList).forEach(key => {
        const room = roomList[key]

        const roomContacts = room.users.filter(
          user => user._id !== this.currentUserId
        )

        let roomAvatar = ''
        if (roomContacts.length === 1 && roomContacts[0].avatar) {
          roomAvatar = roomContacts[0].avatar
        } else {
          roomAvatar = 'avatar' in room && room.avatar ? room.avatar : require('./assets/group.jpg')
        }

        formattedRooms.push({
          ...room,
          roomId: key,
          avatar: roomAvatar,
          index: room.lastUpdated.seconds,
          lastMessage: {
            content: 'Novo chat',
            timestamp: this.formatTimestamp(
              new Date(room.lastUpdated.seconds),
              room.lastUpdated
            )
          }
        })
      })

      // Finally add the newly formatted rooms to component data
      // and listen on last message.
      this.rooms = this.rooms.concat(formattedRooms)
      formattedRooms.map(room => this.listenLastMessage(room))

      if (!this.rooms.length) {
        this.loadingRooms = false
        this.roomsLoadedCount = 0
      }

      // Listen on the users conectivity for these new rooms.
      // And listen on the rooms, to see which users are typing, and to set lastUpdated
      this.listenUsersOnlineStatus(formattedRooms)
      this.listenRooms(query)
      // setTimeout(() => console.log('TOTAL', this.dbRequestCount), 2000)
    },

    listenLastMessage(room) {
      if (!room) return
      const listener = this.firebaseChatService.getMessagesRef(room.roomId)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot(messages => {
          // this.incrementDbCounter('Listen Last Room Message', messages.size)
          messages.forEach(message => {
            const lastMessage = this.formatLastMessage(message.data())
            const roomIndex = this.rooms.findIndex(
              r => room.roomId === r.roomId
            )
            this.rooms[roomIndex].lastMessage = lastMessage
            this.rooms = [...this.rooms]
          })
          if (this.loadingLastMessageByRoom < this.rooms.length) {
            this.loadingLastMessageByRoom++

            if (this.loadingLastMessageByRoom === this.rooms.length) {
              this.loadingRooms = false
              this.roomsLoadedCount = this.rooms.length
            }
          }
        })

      this.roomsListeners.push(listener)
    },

    formatLastMessage(message) {
      if (!message.timestamp) return

      let content = message.content
      if (message.file) content = `${message.file.name}.${message.file.extension ||
          message.file.type}`

      return {
        ...message,
        ...{
          content,
          timestamp: this.formatTimestamp(
            new Date(message.timestamp.seconds * 1000),
            message.timestamp
          ),
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
            message.sender_id !== this.currentUserId &&
            (!message.seen || !message.seen[this.currentUserId])
        }
      }
    },

    formatTimestamp(date, timestamp) {
      const timestampFormat = isSameDay(date, new Date()) ? 'HH:mm' : 'DD/MM/YY'
      const result = parseTimestamp(timestamp, timestampFormat)
      return timestampFormat === 'HH:mm' ? `Today, ${result}` : result
    },

    fetchMessages({ room, options = {} }) {
      this.$emit('show-demo-options', false)
      if (!room) return

      if (options.reset) this.resetMessages()

      if (this.endMessages && !this.startMessages) return (this.messagesLoaded = true)

      const ref = this.firebaseChatService.getMessagesRef(room.roomId)

      let query = ref.orderBy('timestamp', 'desc').limit(this.messagesPerPage)

      if (this.startMessages) query = query.startAfter(this.startMessages)

      this.selectedRoom = room.roomId

      query.get().then(messages => {
        // this.incrementDbCounter('Fetch Room Messages', messages.size)
        if (this.selectedRoom !== room.roomId) return

        if (messages.empty) this.messagesLoaded = true

        if (this.startMessages) this.endMessages = this.startMessages
        this.startMessages = messages.docs[messages.docs.length - 1]

        let listenerQuery = ref.orderBy('timestamp')

        if (this.startMessages) listenerQuery = listenerQuery.startAfter(this.startMessages)
        if (this.endMessages) listenerQuery = listenerQuery.endAt(this.endMessages)

        if (options.reset) this.messages = []

        messages.forEach(message => {
          const formattedMessage = this.formatMessage(room, message)
          this.messages.unshift(formattedMessage)
        })

        const listener = listenerQuery.onSnapshot(snapshots => {
          // this.incrementDbCounter('Listen Room Messages', snapshots.size)
          this.listenMessages(snapshots, room)
        })
        this.listeners.push(listener)
      })
    },

    listenMessages(messages, room) {
      messages.forEach(message => {
        const formattedMessage = this.formatMessage(room, message)
        const messageIndex = this.messages.findIndex(m => m._id === message.id)

        if (messageIndex === -1) {
          this.messages = this.messages.concat([formattedMessage])
        } else {
          this.$set(this.messages, messageIndex, formattedMessage)
        }

        this.markMessagesSeen(room, message)
      })
    },

    markMessagesSeen(room, message) {
      if (
        message.data().sender_id !== this.currentUserId &&
        (!message.data().seen || !message.data().seen[this.currentUserId])
      ) {
        this.firebaseChatService.getMessagesRef(room.roomId)
          .doc(message.id)
          .update({
            [`seen.${this.currentUserId}`]: new Date()
          })
      }
    },

    formatMessage(room, message) {
      const senderUser = room.users.find(
        user => message.data().sender_id === user._id
      )

      const { sender_id, timestamp } = message.data()

      return {
        ...message.data(),
        ...{
          senderId: sender_id,
          _id: message.id,
          seconds: timestamp.seconds,
          timestamp: parseTimestamp(timestamp, 'HH:mm'),
          date: parseTimestamp(timestamp, 'DD MMMM YYYY'),
          username: senderUser ? senderUser.username : null,
          // avatar: senderUser ? senderUser.avatar : null,
          distributed: true
        }
      }
    },

    async sendMessage({ content, roomId, file, replyMessage }) {
      const message = {
        sender_id: this.currentUserId,
        content,
        timestamp: new Date()
      }

      if (file) {
        message.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.localUrl
        }
        if (file.audio) {
          message.file.audio = true
          message.file.duration = file.duration
        }
      }

      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        }

        if (replyMessage.file) {
          message.replyMessage.file = replyMessage.file
        }
      }

      const { id } = await this.firebaseChatService.getMessagesRef(roomId).add(message)

      if (file) this.uploadFile({ file, messageId: id, roomId })

      this.firebaseChatService.roomsRef.doc(roomId).update({ lastUpdated: new Date() })
    },

    openFile({ message }) {
      window.open(message.file.url, '_blank')
    },

    async openUserTag({ user }) {
      let roomId = null

      this.rooms.forEach(room => {
        if (room.users.length === 2) {
          const userId1 = room.users[0]._id
          const userId2 = room.users[1]._id
          if (
            (userId1 === user._id || userId1 === this.currentUserId) &&
            (userId2 === user._id || userId2 === this.currentUserId)
          ) {
            roomId = room.roomId
          }
        }
      })

      if (roomId) return (this.roomId = roomId)

      const query1 = await this.firebaseChatService.roomsRef
        .where('users', '==', [this.currentUserId, user._id])
        .get()

      if (!query1.empty) {
        return this.loadRoom(query1)
      }

      const query2 = await this.firebaseChatService.roomsRef
        .where('users', '==', [user._id, this.currentUserId])
        .get()

      if (!query2.empty) {
        return this.loadRoom(query2)
      }

      const room = await this.firebaseChatService.roomsRef.add({
        users: [user._id, this.currentUserId],
        lastUpdated: new Date()
      })

      this.roomId = room.id
      this.fetchRooms()
    },

    async loadRoom(query) {
      query.forEach(async room => {
        if (this.loadingRooms) return
        await this.firebaseChatService.roomsRef.doc(room.id).update({ lastUpdated: new Date() })
        this.roomId = room.id
        this.fetchRooms()
      })
    },

    async editMessage({ messageId, newContent, roomId, file }) {
      const newMessage = { edited: new Date() }
      newMessage.content = newContent

      if (file) {
        newMessage.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl
        }
        if (file.audio) {
          newMessage.file.audio = true
          newMessage.file.duration = file.duration
        }
      } else {
        newMessage.file = this.firebaseChatService.deleteDbField
      }

      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(messageId)
        .update(newMessage)

      if (file) this.uploadFile({ file, messageId, roomId })
    },

    async deleteMessage({ message, roomId }) {
      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(message._id)
        .update({ deleted: new Date() })

      const { file } = message

      if (file) {
        const deleteFileRef = this.firebaseChatService.filesRef
          .child(this.currentUserId)
          .child(message._id)
          .child(`${file.name}.${file.extension || file.type}`)

        await deleteFileRef.delete()
      }
    },

    async uploadFile({ file, messageId, roomId }) {
      let type = file.extension || file.type
      if (type === 'svg' || type === 'pdf') {
        type = file.type
      }

      const uploadFileRef = this.firebaseChatService.filesRef
        .child(this.currentUserId.toString())
        .child(messageId)
        .child(`${file.name}.${type}`)

      await uploadFileRef.put(file.blob, { contentType: type })
      const url = await uploadFileRef.getDownloadURL()
      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(messageId)
        .update({
          ['file.url']: url
        })
    },

    menuActionHandler({ action, roomId }) {
      let result = null // This is only used when deleting the room.
      switch (action.name) {
      case 'updateRoom':
        this.formRoomId = roomId
        return this.showRoomForm(roomId)
      case 'deleteRoom':
        this.$utils.browser.confirmationDialog(this.$vs,
          'Tem certeza que quer excluir esse grupo de chat?',
          () => {
            result = this.deleteRoom(roomId)
          }
        )
        return result
      }
    },

    async sendMessageReaction({ reaction, remove, messageId, roomId }) {
      const dbAction = remove
        ? this.firebaseChatService.firebase.firestore.FieldValue.arrayRemove(this.currentUserId)
        : this.firebaseChatService.firebase.firestore.FieldValue.arrayUnion(this.currentUserId)

      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(messageId)
        .update({
          [`reactions.${reaction.name}`]: dbAction
        })
    },

    typingMessage({ message, roomId }) {
      if (message && message.length > 1) {
        return (this.typingMessageCache = message)
      }

      if (message && message.length === 1 && this.typingMessageCache) {
        return (this.typingMessageCache = message)
      }

      this.typingMessageCache = message

      const dbAction = message
        ? this.firebaseChatService.firebase.firestore.FieldValue.arrayUnion(this.currentUserId)
        : this.firebaseChatService.firebase.firestore.FieldValue.arrayRemove(this.currentUserId)

      this.firebaseChatService.roomsRef.doc(roomId).update({
        typingUsers: dbAction
      })
    },

    async listenRooms(query) {
      const listener = query.onSnapshot(rooms => {
        // this.incrementDbCounter('Listen Rooms Typing Users', rooms.size)
        rooms.forEach(room => {
          const foundRoom = this.rooms.find(r => r.roomId === room.id)
          if (foundRoom) {
            foundRoom.typingUsers = room.data().typingUsers
            foundRoom.index = room.data().lastUpdated.seconds
          }
        })
      })
      this.roomsListeners.push(listener)
    },

    updateUserOnlineStatus() {
      const userStatusRef = this.firebaseChatService.firebase
        .database()
        .ref(`/status/${  this.currentUserId}`)

      const isOfflineData = {
        state: 'offline',
        lastChanged: this.firebaseChatService.firebase.database.ServerValue.TIMESTAMP
      }

      const isOnlineData = {
        state: 'online',
        lastChanged: this.firebaseChatService.firebase.database.ServerValue.TIMESTAMP
      }

      this.firebaseChatService.firebase
        .database()
        .ref('.info/connected')
        .on('value', snapshot => {
          if (snapshot.val() === false) return

          userStatusRef
            .onDisconnect()
            .set(isOfflineData)
            .then(() => {
              userStatusRef.set(isOnlineData)
            })
        })
    },

    listenUsersOnlineStatus(rooms) {
      rooms.map(room => {
        room.users.map(user => {
          const listener = this.firebaseChatService.firebase
            .database()
            .ref(`/status/${  user._id}`)
            .on('value', snapshot => {
              if (!snapshot || !snapshot.val()) return

              const lastChanged = this.formatTimestamp(
                new Date(snapshot.val().lastChanged),
                new Date(snapshot.val().lastChanged)
              )

              user.status = { ...snapshot.val(), lastChanged }

              const roomIndex = this.rooms.findIndex(
                r => room.roomId === r.roomId
              )

              this.$set(this.rooms, roomIndex, room)
            })
          this.roomsListeners.push(listener)
        })
      })
    },

    showRoomForm(roomId = null) {
      const form = this.$refs.chatroomform
      form.chatroomId = roomId
      if (roomId) this.$vs.loading()
      form.fillForm().then(() => { this.$vs.loading.close() })
      this.dlog('Opening chat room form. id:', roomId)
      this.viewGroupFormModal = true
    },

    async roomAltered() {
      this.viewGroupFormModal = false
      this.formRoomId = null
      this.fetchRooms()
    },

    inviteUser(roomId) {
      this.resetForms()
      this.inviteRoomId = roomId
    },

    async addRoomUser() {
      this.disableForm = true

      const { id } = await this.firebaseChatService.usersRef.add({ username: this.invitedUsername })
      await this.firebaseChatService.usersRef.doc(id).update({ _id: id })

      await this.firebaseChatService.roomsRef
        .doc(this.inviteRoomId)
        .update({ users: this.firebaseChatService.firebase.firestore.FieldValue.arrayUnion(id) })

      this.inviteRoomId = null
      this.invitedUsername = ''
      this.fetchRooms()
    },

    removeUser(roomId) {
      this.resetForms()
      this.removeRoomId = roomId
      this.removeUsers = this.rooms.find(room => room.roomId === roomId).users
    },

    async deleteRoomUser() {
      this.disableForm = true

      await this.firebaseChatService.roomsRef.doc(this.removeRoomId).update({
        users: this.firebaseChatService.firebase.firestore.FieldValue.arrayRemove(this.removeUserId)
      })

      this.removeRoomId = null
      this.removeUserId = ''
      this.fetchRooms()
    },

    async deleteRoom(roomId) {
      const room = this.rooms.find(r => r.roomId === roomId)
      if (!this.$acl.hasPermission('chat.delete_rooms')) {
        return alert('Você não possui permissão para excluir esta sala.')
      }

      const ref = this.firebaseChatService.getMessagesRef(roomId)

      ref.get().then(res => {
        if (res.empty) return
        res.docs.map(doc => ref.doc(doc.id).delete())
      })

      await this.firebaseChatService.roomsRef.doc(roomId).delete()
      this.fetchRooms()
    },

    resetForms() {
      this.disableForm = false
      this.addNewRoom = null
      this.addRoomUsername = ''
      this.inviteRoomId = null
      this.invitedUsername = ''
      this.removeRoomId = null
      this.removeUserId = ''
    }
    /*
    ,incrementDbCounter(type, size) {
      size = size || 1
      this.dbRequestCount += size
      console.log(type, size)
    }
    */
  }
}
</script>

<style lang="scss" scoped>
.window-container {
  width: 100%;
}

.window-mobile {
  form {
    padding: 0 10px 10px;
  }
}

form {
  padding-bottom: 20px;
}

input {
  padding: 5px;
  width: 140px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid #d2d6da;
  outline: none;
  font-size: 14px;
  vertical-align: middle;

  &::placeholder {
    color: #9ca6af;
  }
}

button {
  background: #1976d2;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 10px;
  border: none;
  font-size: 14px;
  transition: 0.3s;
  vertical-align: middle;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: initial;
    background: #c6c9cc;
    opacity: 0.6;
  }
}

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}

select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>
